import {Filter, IFilter} from '@staphyt/api';

export interface IMonitoringFilter extends IFilter {
  'date[before]'?: Date;
  'date[after]'?: Date;
  'date[strictly_before]'?: Date;
  'date[strictly_after]'?: Date;
  'client.identifier'?: string[];
  status?: string[];
}

export class MonitoringFilter extends Filter {

  public toInterface(map: Map<string, any>): IMonitoringFilter {
    const filters: IMonitoringFilter = super.toInterface(map);
    map.forEach((value, key) => {
      switch (key) {
        case 'date[before]' :
          filters['date[before]'] = this.dateToInterface(value);
          break;
        case 'date[after]' :
          filters['date[after]'] = this.dateToInterface(value);
          break;
        case 'date[strictly_before]' :
          filters['date[strictly_before]'] = this.dateToInterface(value);
          break;
        case 'date[strictly_after]' :
          filters['date[strictly_after]'] = this.dateToInterface(value);
          break;
        case 'client.identifier':
          filters['client.identifier'] = value.split('|');
          break;
        case 'status':
          filters.status = value.split('|');
          break;
      }
    });
    return filters;
  }

  public toMap(filters: IMonitoringFilter): Map<string, any> {
    const params: Map<string, any> = super.toMap(filters);
    if (filters['date[before]']) {
      params.set('date[before]', this.dateToString(filters['date[before]']));
    }
    if (filters['date[after]']) {
      params.set('date[after]', this.dateToString(filters['date[after]']));
    }
    if (filters['date[strictly_before]']) {
      params.set('date[strictly_before]', this.dateToString(filters['date[strictly_before]']));
    }
    if (filters['date[strictly_after]']) {
      params.set('date[strictly_after]', this.dateToString(filters['date[strictly_after]']));
    }
    if (filters['client.identifier'] && filters['client.identifier'].length > 0) {
      params.set('client.identifier[]', filters['client.identifier']);
    }
    if (filters.status && filters.status.length > 0) {
      params.set('status[]', filters.status);
    }
    return params;
  }
}
