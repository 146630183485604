<h1 mat-dialog-title>
  Search a region
  <mat-icon class="cursor-pointer" aria-hidden="false" [inline]="true" [mat-dialog-close]="null">close</mat-icon>
</h1>
<div mat-dialog-content>
  <app-netsuite-region-list
    [isLoading]="isLoading"
    [data]="regions"
    [displayedColumns]="displayedColumns"
    (clickRegion)="onRegion($event)">
  </app-netsuite-region-list>
</div>
