import {Injectable} from '@angular/core';
import {ApiStaphytService} from '@staphyt/api';
import {Oauth2Client} from '../../models/oauth2-client';
import {DynamicListService} from '@staphyt/service';

const API_PATH = '/oauth2/clients';

@Injectable()
export class Oauth2ClientService extends DynamicListService {

  constructor(apiService: ApiStaphytService) {
    super(apiService, API_PATH, Oauth2Client);
  }

  public findWithIdentifier(identifier: string): Oauth2Client | null {
    if (null !== identifier) {
      this.isNotEmptyList();
      const itemHydrated = (identifier ? this.list.find((x: Oauth2Client) => x.identifier === identifier) : null);
      if (null === itemHydrated) {
        console.warn('Unable to find item. Do you have use the method fetch before use the "get" function? (' + this.constructor.name + ')');
      } else {
        return itemHydrated;
      }
    }
    return null;
  }
}
