import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Region} from '@staphyt/common';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

export const REGION_LIST_DC_NAME = 'name';
export const REGION_LIST_DC_NETSUITE_ID = 'netSuiteId';
export const REGION_LIST_DC_ENABLED = 'enabled';
export const REGION_LIST_DC_LEADER = 'leader';
export const REGION_LIST_DC_REMOVE = 'remove';
export const REGION_LIST_ALL_DC = [
  REGION_LIST_DC_NAME,
  REGION_LIST_DC_NETSUITE_ID,
  REGION_LIST_DC_ENABLED,
  REGION_LIST_DC_LEADER,
  REGION_LIST_DC_REMOVE
];

export interface IRemoveRegion {
  event: MouseEvent;
  region: Region;
}

@Component({
  selector: 'app-netsuite-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.scss']
})
export class RegionListComponent implements OnInit {
  @ViewChild('tableContainer', {static: true}) tableContainer!: ElementRef;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild('filterInput', {static: true}) filterInput!: ElementRef;
  @Input() isLoading = true;
  @Input() showFilterInput = true;
  @Input() showPaginator = true;
  @Input() displayedColumns: string[] = REGION_LIST_ALL_DC;
  @Output() clickRegion = new EventEmitter<Region>();
  @Output() removeRegion = new EventEmitter<IRemoveRegion>();

  public dataSource = new MatTableDataSource<Region>();

  @Input()
  set data(data: Region[]|Region) {
    if (data) {
      if (data instanceof Region) {
        this.dataSource.data = [data];
        this.showPaginator = false;
        this.showFilterInput = false;
        this.sort.disabled = true;
      } else {
        this.dataSource.data = data;
      }
    }
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe(() => {
      this.tableContainer.nativeElement.scrollTop = 0;
    });

    this.filterInput.nativeElement.focus();
  }

  public click(region: Region) {
    this.clickRegion.emit(region);
  }

  public remove(event: MouseEvent, region: Region) {
    this.removeRegion.emit({
      event,
      region
    });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearFilter() {
    this.filterInput.nativeElement.value = '';
    this.dataSource.filter = '';
  }
}
