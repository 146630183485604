import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavigationComponent} from './layout/navigation/navigation.component';
import {TopComponent} from './layout/top/top.component';
import {RootComponent} from './root/root.component';
import {NetsuiteComponent} from './netsuite/netsuite.component';
import {ApiStaphytService, AuthenticationGuardService, OAuthService, RoleGuardService} from '@staphyt/api';
import {
  CompanyService,
  ContinentService,
  CountryService, OrganizationService,
  ProductService,
  RegionService,
  ServiceModule,
  StaphytServiceService,
  StationService,
  UserService
} from '@staphyt/service';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {UiModule} from '@staphyt/ui';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSortModule} from '@angular/material/sort';
import {ServiceComponent} from './netsuite/service/service.component';
import {ServiceFormComponent} from './netsuite/service/form/service-form.component';
import {RegionComponent} from './netsuite/region/region.component';
import {RegionFormComponent} from './netsuite/region/form/region-form.component';
import {StationComponent} from './station/station.component';
import {StationFormComponent} from './station/form/station-form.component';
import {RegionListComponent} from './netsuite/region/list/region-list.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ServiceListComponent} from './netsuite/service/list/service-list.component';
import {ServiceSearchDialogComponent} from './netsuite/service/search/service-search-dialog.component';
import {EnumGetValueWithKeyPipe} from './enum-get-value-with-key.pipe';
import {CommandComponent} from './command/command.component';
import {ProductComponent} from './product/product.component';
import {MonitoringComponent} from './monitoring/monitoring.component';
import {MonitoringService} from './monitoring/services/monitoring.service';
import {DialogLogComponent} from './monitoring/dialog-log/dialog-log.component';
import {Oauth2ClientService} from './monitoring/services/oauth2-client/oauth2-client.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {enGB} from 'date-fns/locale';
import {MatDateFnsModule} from '@angular/material-date-fns-adapter';
import {MatListModule} from '@angular/material/list';
import {UserListComponent} from './user/list/user-list.component';
import {RegionSearchDialogComponent} from './netsuite/region/search/region-search-dialog.component';
import {UserComponent} from './user/user.component';
import {DialogLoadingComponent} from './layout/overlay/dialog-loading/dialog-loading.component';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy',          //e.g. "23/12/2021"
  },
  display: {
    // https://stackoverflow.com/a/68810517
    dateInput: 'dd/MM/yyyy',          //e.g. "23 Dec 2021"
    monthYearLabel: 'LLL yyyy',       //e.g. "Dec 2021"
    dateA11yLabel: 'dd LLL yyyy',     //e.g. "23 Dec 2021"
    monthYearA11yLabel: 'LLLL yyyy',  //e.g. "December 2021"
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TopComponent,
    RootComponent,
    NetsuiteComponent,
    ServiceComponent,
    ServiceFormComponent,
    RegionComponent,
    RegionFormComponent,
    StationComponent,
    StationFormComponent,
    RegionListComponent,
    ServiceListComponent,
    ServiceSearchDialogComponent,
    EnumGetValueWithKeyPipe,
    CommandComponent,
    ProductComponent,
    MonitoringComponent,
    DialogLogComponent,
    UserListComponent,
    RegionSearchDialogComponent,
    UserComponent,
    DialogLoadingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTableModule,
    MatCardModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    UiModule,
    MatProgressBarModule,
    MatSortModule,
    ServiceModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDateFnsModule,
    MatListModule
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: enGB},
    {provide: 'API_BASE_URL', useValue: environment.apiBaseUrl},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd LLL yyyy' }},
    OAuthService,
    ApiStaphytService,
    RoleGuardService,
    StaphytServiceService,
    UserService,
    RegionService,
    StationService,
    CountryService,
    ContinentService,
    ProductService,
    AuthenticationGuardService,
    MonitoringService,
    Oauth2ClientService,
    CompanyService,
    OrganizationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
