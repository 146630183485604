import {AfterViewChecked, ChangeDetectorRef, Component} from '@angular/core';
import {
  API_EVENT_ERROR_TYPE,
  API_EVENT_INFO_TYPE,
  API_EVENT_SUCCESS_TYPE,
  ApiStaphytErrorEvent, ApiStaphytEvent,
  ApiStaphytInfoEvent,
  ApiStaphytService,
  ApiStaphytSuccessEvent,
  AuthConfig,
  OAuthService
} from '@staphyt/api';
import {environment} from '../environments/environment';
import {STATION_ALL_CHILD_OBJECT, StationService, USER_ALL_CHILD_OBJECT, UserService} from '@staphyt/service';
import {MatSnackBar} from '@angular/material/snack-bar';

const authConfig: AuthConfig = {
  clientId: environment.apiClientId,
  redirectUri: environment.loginRedirectUri,
  loginUrl: environment.loginUrl,
  logoutUrl: environment.logoutUrl,
  tokenEndpoint: environment.tokenEndpoint,
  scope: 'admin'
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  public loading = false;

  constructor(private oauthService: OAuthService,
              private apiStaphytService: ApiStaphytService,
              private cd: ChangeDetectorRef,
              private userService: UserService,
              private stationService: StationService,
              private matSnackBar: MatSnackBar) {
    this.initAuthService();
    this.initLoadingForApiStaphytService();
    this.stationService.setChildObjectConfig(STATION_ALL_CHILD_OBJECT);
    this.userService.setChildObjectConfig(USER_ALL_CHILD_OBJECT);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  private initAuthService() {
    this.oauthService.configure(new AuthConfig(authConfig));

    // call this method to know if the oAuthservice is ready (accessToken is OK)
    if (!this.oauthService.isAuthenticated()) {
      // try to login user hidden, if not a success => launch login url
      this.oauthService.tryLogin().then((success: boolean) => {
        if (!success) {
          this.oauthService.launchLogin();
        }
      });
    }
    // launch this method to setup the silent refresh
    this.oauthService.setupAutomaticSilentRefresh();
  }

  // Shows and hides the loading spinner during API call
  private initLoadingForApiStaphytService(): void {
    this.apiStaphytService.events.subscribe((e: ApiStaphytEvent) => {
      if (e instanceof ApiStaphytSuccessEvent) {
        if (e.type === API_EVENT_SUCCESS_TYPE.REQUEST_SUCCESS) {
          this.loading = false;
        }
      }
      if (e instanceof ApiStaphytInfoEvent) {
        if (e.type === API_EVENT_INFO_TYPE.LAUNCH_REQUEST) {
          this.loading = true;
        }
      }
      if (e instanceof ApiStaphytErrorEvent) {
        if (e.type === API_EVENT_ERROR_TYPE.ERROR) {
          this.matSnackBar.open(e.apiStaphytError.title + ' #' + e.apiStaphytError.statusCode + ' : ' + e.apiStaphytError.description, 'Got it!', {
            verticalPosition: 'top'
          });
          this.loading = false;
        }
      }
    });
  }
}
