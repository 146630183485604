import {Expose} from 'class-transformer';

export interface IOauth2Client {
  identifier: string;
  name: string;
}

export class Oauth2Client implements IOauth2Client {
  @Expose({ groups: ['get'] })
  identifier!: string;
  @Expose({ groups: ['get'] })
  name!: string;
}
