<h2 mat-dialog-title>
  {{ station.name }}
</h2>
<form [formGroup]="stationForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div mat-dialog-content>
    <p>
      <span style="display:block; color:rgba(0,0,0,.54); font-size: 10.4px;">
        Region
      </span>
      <app-netsuite-region-list *ngIf="region"
                         [displayedColumns]="regionDisplayedColumns"
                         [data]="region"
                         [isLoading]="false"
                         (removeRegion)="clearRegion()">
      </app-netsuite-region-list>
      <button mat-raised-button color="primary" *ngIf="!region" type="button" (click)="setRegion()">Set region</button>
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!stationForm.valid" type="submit">Save</button>
  </div>
</form>
