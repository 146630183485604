import { Component, OnInit } from '@angular/core';
import {UserService} from '@staphyt/service';
import {ROLES, User} from '@staphyt/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public hasRoleNetSuite = false;
  public hasRoleAdministrator = false;
  public hasRoleProduct = false;

  public showNetsuite = true;
  public showAdministrator = true;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.currentLoggedUser().then((user: User) => {
      this.hasRoleNetSuite = user.hasExpectedRole(ROLES.ROLE_ADMIN_NETSUITE);
      this.hasRoleAdministrator = user.hasExpectedRole(ROLES.ROLE_ADMINISTRATOR);
      this.hasRoleProduct = user.hasExpectedRole(ROLES.ROLE_ADMIN_PRODUCT);
    });
  }

}
