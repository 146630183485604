import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumGetValueWithKeyPipe' })
/**
 * @deprecated Please use now the class of the library (in @staphyt/common library)
 */
export class EnumGetValueWithKeyPipe implements PipeTransform {
  /**
   * @deprecated Please use now the class of the library (in @staphyt/common library)
   */
  transform(enumV: any, ...args: any[]) {
    let result = null;
    Object.keys(enumV).some((value, index) => {
      if (value === args[0]) {
        result = Object.values(enumV)[index];
        return true;
      }
      return false;
    });
    return result;
  }
}
