<mat-form-field [hidden]="!showFilterInput">
  <mat-label>Filter</mat-label>
  <input matInput #filterInput (keyup)="applyFilter($event)" autocomplete="off" placeholder="Filter by code or name">
  <button *ngIf="filterInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<div class="service-list-container mat-elevation-z8">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <div #tableContainer class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let row">{{row.code}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
          <mat-icon class="cursor-help" aria-hidden="false" [inline]="true" matTooltip="Premium-RH">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">{{row.type}}</td>
      </ng-container>

      <ng-container matColumnDef="parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent</th>
        <td mat-cell *matCellDef="let row">{{row.parent ? row.parent.code : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Enabled
          <mat-icon class="cursor-help" aria-hidden="false" [inline]="true" matTooltip="Premium-RH">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">{{row.enabled ? 'Yes' : 'No'}}</td>
      </ng-container>

      <ng-container matColumnDef="supervisor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Supervisor</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.supervisor;then supervisor else no"></div>
          <ng-template #supervisor><staphyt-user-popup [user]="row.supervisor"></staphyt-user-popup></ng-template>
          <ng-template #no>-</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="netSuiteAllowed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NetSuite allowed</th>
        <td mat-cell *matCellDef="let row">{{row.netSuiteAllowed !== undefined ? (row.netSuiteAllowed ? 'Yes' : 'No') : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="netSuiteType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NetSuite type</th>
        <td mat-cell *matCellDef="let row">{{row.netSuiteType ? (_serviceNetsuiteType | enumGetValueWithKeyPipe:row.netSuiteType) : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="regulatory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Regulatory
          <mat-icon class="cursor-help" aria-hidden="false" [inline]="true" matTooltip="Nécessaire pour assigner le bon rôle : Regulatory... (quand vrai), sinon Agroscience (quand faux)">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">{{row.regulatory ? 'Yes' : 'No'}}</td>
      </ng-container>

      <ng-container matColumnDef="hrSupervisor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>HR Supervisor</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.hrSupervisor;then hrSupervisor else no"></div>
          <ng-template #hrSupervisor><staphyt-user-popup [user]="row.hrSupervisor"></staphyt-user-popup></ng-template>
          <ng-template #no>-</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="approvalLimit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approval limit</th>
        <td mat-cell *matCellDef="let row">{{row.approvalLimit ? row.approvalLimit : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="stationApproval">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Station approval
          <mat-icon class="cursor-help" aria-hidden="false" [inline]="true" matTooltip="Si l'approbateur d'achat est le responsable de station (vrai), ou le N+1 (faux)">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">{{row.stationApproval ? 'Yes' : 'No'}}</td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-icon class="cursor-pointer material-icons-outlined" aria-hidden="false" (click)="remove($event, row);$event.stopPropagation()" matTooltip="Delete">delete_outline</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="dataSourceEmpty">
        <td mat-cell *matFooterCellDef>
          No data available
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="click(row)"></tr>
      <tr mat-footer-row *matFooterRowDef="['dataSourceEmpty']" [hidden]="dataSource.filteredData.length > 0"></tr>
    </table>
  </div>
  <mat-paginator [hidden]="!showPaginator" [pageSize]="20" showFirstLastButtons></mat-paginator>
</div>
