import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-log',
  templateUrl: './dialog-log.component.html',
  styleUrls: ['./dialog-log.component.scss']
})
export class DialogLogComponent implements OnInit {
  public log!: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
  }

  public static open(dialog: MatDialog, data: string) {
    return dialog.open(DialogLogComponent, {
      data
    });
  }

  ngOnInit() {
    this.log = this.data;
  }
}
