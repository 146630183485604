import {ApiStaphytResponse, ApiStaphytService, IFilter} from '@staphyt/api';
import {Injectable} from '@angular/core';
import {CrudService, UserService} from '@staphyt/service';
import {Monitoring} from '../models/monitoring';
import {IMonitoringFilter, MonitoringFilter} from '../filters/monitoring-filter';
import {Oauth2ClientService} from './oauth2-client/oauth2-client.service';
import {User} from '@staphyt/common';

interface IFetchChildObjectResult {
  users: User[]
}

const API_PATH = '/monitorings';

@Injectable()
export class MonitoringService extends CrudService {

  constructor(apiService: ApiStaphytService,
              private userService: UserService,
              private oauth2ClientService: Oauth2ClientService) {
    super(apiService, API_PATH, Monitoring);
  }

  public getCollection(filters?: IMonitoringFilter): Promise<ApiStaphytResponse> {
    return new Promise((resolve) => {
      // @ts-ignore
      super.getCollection(filters).then((apiStaphytResponse: ApiStaphytResponse) => {
        this.fetchChildObject(apiStaphytResponse.member).then((fetchChildObjectResult: IFetchChildObjectResult) => {
          apiStaphytResponse.member.map((monitoring: Monitoring) => {
            this.setChildObject(monitoring, fetchChildObjectResult);
          });
          resolve(apiStaphytResponse);
        });
      });
    });
  }

  public filterToMap(filters: IFilter): Map<string, string> {
    return new MonitoringFilter().toMap(filters);
  }

  protected fetchChildObject(monitorings: Monitoring[]): Promise<IFetchChildObjectResult> {
    return new Promise((resolve) => {
      const promises = [];
      const fetchChildObjectResult: IFetchChildObjectResult = {
        users: []
      };
      const userIds: number[] = [];
      monitorings.map((monitoring: Monitoring) => {
        if (monitoring.user) {
          const id: number = this.extractIdFromIRI(monitoring.user);
          // check if already in array
          if (!userIds.includes(id)) {
            userIds.push(id);
          }
        }
      });
      if (userIds.length > 0) {
        promises.push(this.userService.getCollection({
          ids: userIds,
          pagination: false
        }).then((apiStaphytResponse: ApiStaphytResponse) => {
          fetchChildObjectResult.users = apiStaphytResponse.member;
        }));
      }
      Promise.all(promises).then(() => {
        resolve(fetchChildObjectResult);
      });
    });
  }

  protected setChildObject(monitoring: Monitoring, fetchChildObjectResult: IFetchChildObjectResult): void {
    monitoring.user = fetchChildObjectResult.users.find((x: User) => x['@id'] === (monitoring.user as any));
    // @ts-ignore
    monitoring.client = this.oauth2ClientService.findWithIdentifier(monitoring.client.identifier);
    // TODO user
    if (monitoring.user) {

    }
  }
}
