<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Liste des utilisateurs</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <staphyt-user-list
      [displayedColumns]="displayedColumns"
      [columnsToFilter]="columnsToFilter"
      [pageSize]="pageSize"
      [data]="iListData"
      [isDynamicData]="true"
      [autoFocusSearch]="true"
      (clickUser)="onUser($event)"
      [isLoading]="isLoading"
      (paginateChange)="onPaginateChange($event)"
      (searchChange)="onSearchChange($event)">
    </staphyt-user-list>
  </mat-card-content>
</mat-card>
