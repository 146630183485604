import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiStaphytResponse} from '@staphyt/api';
import {MatTableDataSource} from '@angular/material/table';
import {MonitoringService} from './services/monitoring.service';
import {Monitoring, MONITORING_STATUS} from './models/monitoring';
import {IMonitoringFilter} from './filters/monitoring-filter';
import {KeyValue} from '@angular/common';
import {P} from '@angular/cdk/keycodes';
import {MatSelect} from '@angular/material/select';
import {DialogLogComponent} from './dialog-log/dialog-log.component';
import {Oauth2ClientService} from './services/oauth2-client/oauth2-client.service';
import {Oauth2Client} from './models/oauth2-client';
import {FormControl, FormGroup} from '@angular/forms';

export const MONITORING_LIST_DC_CLIENT = 'client';
export const MONITORING_LIST_DC_ACTION = 'action';
export const MONITORING_LIST_DC_DATE = 'date';
export const MONITORING_LIST_DC_LOG = 'log';
export const MONITORING_LIST_DC_ERROR_LOG = 'errorLog';
export const MONITORING_LIST_DC_SESSION = 'session';
export const MONITORING_LIST_DC_STATUS = 'status';
export const MONITORING_LIST_ALL_DC = [
  MONITORING_LIST_DC_CLIENT,
  MONITORING_LIST_DC_ACTION,
  MONITORING_LIST_DC_DATE,
  MONITORING_LIST_DC_STATUS,
  MONITORING_LIST_DC_SESSION,
  MONITORING_LIST_DC_LOG,
  MONITORING_LIST_DC_ERROR_LOG
];

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {
  @ViewChild('filterInput', {static: true}) filterInput!: ElementRef;
  @ViewChild('filterStatus', {static: true}) selectFilterStatus!: MatSelect;
  @ViewChild('filterOauth2Client', {static: true}) selectFilterOauth2Client!: MatSelect;
  @ViewChild('startDate', {static: true}) startDate!: ElementRef;
  @ViewChild('endDate', {static: true}) endDate!: ElementRef;
  public isLoading = true;
  public displayedColumns: string[] = MONITORING_LIST_ALL_DC;
  public _monitoringStatus = MONITORING_STATUS;
  private filters: IMonitoringFilter = {
    pagination: false
  };

  public dataSource = new MatTableDataSource<Monitoring>();
  public oauth2Clients!: Oauth2Client[];

  public dateRangeForm: FormGroup = new FormGroup({});
  public monitoringMissingEndRecord!: string[];

  constructor(private monitoringService: MonitoringService,
              private dialog: MatDialog,
              oauth2ClientService: Oauth2ClientService) {
    oauth2ClientService.getCollection().then((oauth2Clients) => {
      // @ts-ignore
      this.oauth2Clients = oauth2Clients;
    });
    this.initDateAndDateRangePicker();
    this.loadList();
  }

  ngOnInit(): void {
    this.selectFilterOauth2Client.valueChange.subscribe((value: Oauth2Client | undefined) => {
      if (value) {
        this.filters['client.identifier'] = [value.identifier];
      } else {
        this.filters['client.identifier'] = undefined;
      }
      this.loadList();
    });
    this.selectFilterStatus.valueChange.subscribe((value) => {
      this.filters.status = value;
      this.loadList();
    });

    this.filterInput.nativeElement.focus();
  }

  private initDateAndDateRangePicker()
  {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 10);
    this.filters['date[after]'] = startDate;
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    this.filters['date[before]'] = endDate;
    this.dateRangeForm = new FormGroup({
      start: new FormControl(startDate),
      end: new FormControl(new Date())
    });
  }

  public click(monitoring: Monitoring, isErrorLog: boolean = false) {
    if (isErrorLog) {
      if (monitoring.errorLog) {
        DialogLogComponent.open(this.dialog, monitoring.errorLog);
      }
    } else {
      if (monitoring.log) {
        DialogLogComponent.open(this.dialog, monitoring.log);
      }
    }
  }

  public clearFilter() {
    this.filterInput.nativeElement.value = '';
    this.dataSource.filter = '';
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private loadList() {
    this.isLoading = true;
    this.monitoringService.getCollection(this.filters).then((apiStaphytResponse: ApiStaphytResponse) => {
      this.dataSource.data = apiStaphytResponse.member;
      this.monitoringMissingEndRecord = [];
      apiStaphytResponse.member.map((monitoring: Monitoring) => {
        const session = monitoring.session;
        const index = this.monitoringMissingEndRecord.indexOf(session);
        if (index > -1) {
          this.monitoringMissingEndRecord.splice(index, 1);
        } else {
          this.monitoringMissingEndRecord.push(session);
        }
      });
      this.isLoading = false;
    });
  }

  public getCount(status: KeyValue<string, MONITORING_STATUS[typeof P]>) {
    return this.dataSource.data.filter((monitoring => (monitoring.status === status.key))).length;
  }

  public onDateRangePickerClosed() {
    const start: Date | null = this.dateRangeForm.controls['start'].value;
    const end: Date | null = this.dateRangeForm.controls['end'].value;
    if (null !== start && null !== end) {
      this.filters['date[after]'] = start;
      const endDate = new Date();
      endDate.setDate(end.getDate() + 1);
      this.filters['date[before]'] = endDate;
      this.loadList();
    }
  }

  public hasMonitoringMissingEndRecord(session: string): boolean {
    return this.monitoringMissingEndRecord.includes(session);
  }
}
