import {Component, Inject, OnInit} from '@angular/core';
import {Region, User} from '@staphyt/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {
  USER_LIST_DC_FIRST_NAME,
  USER_LIST_DC_INITIALS,
  USER_LIST_DC_LAST_NAME,
  USER_LIST_DC_REMOVE,
  UserSearchDialogComponent
} from '@staphyt/ui';

export interface IRegionFormComponent {
  region: Region;
}

@Component({
  selector: 'app-region-form',
  templateUrl: './region-form.component.html',
  styleUrls: ['./region-form.component.scss']
})
export class RegionFormComponent implements OnInit {
  public leader?: User;
  public region!: Region;
  public regionForm!: FormGroup;
  public leaderDisplayedColumns: string[] = [
    USER_LIST_DC_INITIALS,
    USER_LIST_DC_FIRST_NAME,
    USER_LIST_DC_LAST_NAME,
    USER_LIST_DC_REMOVE
  ];

  constructor(public dialogRef: MatDialogRef<RegionFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IRegionFormComponent,
              public dialog: MatDialog) { }

  public static open(dialog: MatDialog, data: Region|IRegionFormComponent) {
    if (data instanceof Region) {
      data = {
        region: data
      };
    }
    return dialog.open(RegionFormComponent, {
      data,
      width: '400px'
    });
  }

  ngOnInit() {
    this.region = this.data.region;
    if (this.region.leader) {
      this.leader = this.region.leader;
    }
    this.regionForm = this.region.createForm();
  }

  public onSubmit() {
    // if there is no modification made
    if (this.regionForm.dirty) {
      this.region.updateFromForm(this.regionForm);
      this.region.leader = this.leader;
      this.dialogRef.close(this.region);
    } else {
      this.dialogRef.close(null);
    }
  }

  public setLeader() {
    UserSearchDialogComponent.open(this.dialog).subscribe((user: User) => {
      this.leader = user;
      this.regionForm.markAsDirty();
    });
  }

  public clearLeader() {
    this.leader = undefined;
    this.regionForm.markAsDirty();
  }
}
