<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Station list</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput #filterInput (keyup)="applyFilter($event)" autocomplete="off" placeholder="Filter by code, name, country, city or region">
      <button *ngIf="filterInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="location-list-container mat-elevation-z8">
      <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
      <div #tableContainer class="table-container">
        <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
            <td mat-cell *matCellDef="let row">{{row.code}}</td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
            <td mat-cell *matCellDef="let row">{{row.countryName}}</td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
            <td mat-cell *matCellDef="let row">{{row.city}}</td>
          </ng-container>

          <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
            <td mat-cell *matCellDef="let row">{{row.regionName}}</td>
          </ng-container>

          <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled</th>
            <td mat-cell *matCellDef="let row">{{row.enabled ? 'Yes' : 'No'}}</td>
          </ng-container>

          <ng-container matColumnDef="dataSourceEmpty">
            <td mat-cell *matFooterCellDef>
              No data available
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="click(row)"></tr>
          <tr mat-footer-row *matFooterRowDef="['dataSourceEmpty']" [hidden]="dataSource.filteredData.length > 0"></tr>
        </table>
      </div>
      <mat-paginator [pageSize]="20" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
<div class="alert alert-info" role="alert">
  <strong>Nouvelle station:</strong>
  <ul>
    <li>Benjamin crée dans la GED la nouvelle station</li>
    <li>L'API va ajouter la nouvelle station (synchronisation automatique toutes les heures)</li>
    <li>L'API va ensuite créer automatiquement la station dans NetSuite (06:10 UTC)</li>
    <li>Il faudra ensuite assigner la bonne région manuellement ici</li>
  </ul>
  <strong>Modification d'une station:</strong>
  <ul>
    <li>Benjamin modifie dans la GED la nouvelle station ou la région est définie ici</li>
    <li>L'API va prendre en compte les modifications (synchronisation automatique toutes les heures)</li>
    <li>L'API va ensuite créer mettre à jour la station dans NetSuite (06:10 UTC)</li>
  </ul>
</div>
