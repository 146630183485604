<div class="alert alert-info" role="alert">
  Lors de la création d'une nouvelle région chez Staphyt (Qui recoit l'info... probablement BKI), il faut créer ici l'enregistrement Région pour que NetSuite puisse avoir ces infos avec le régional leader
  <br />
  A ce jour, la mise à jour vers NetSuite se fait automatiquement le lendemain matin.
</div>
<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Region list</mat-card-title>
    <button mat-raised-button color="primary" (click)="add()">Add a region</button>
  </mat-card-header>
  <mat-card-content>
    <app-netsuite-region-list [isLoading]="isLoading" [data]="regions" [displayedColumns]="displayedColumns" (clickRegion)="click($event)" (removeRegion)="remove($event)"></app-netsuite-region-list>
  </mat-card-content>
</mat-card>

