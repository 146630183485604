<nav id="mainnav">
  <ul class="nav flex-column">
    <li>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/product']" [routerLinkActive]="['menu-active']" *ngIf="hasRoleProduct">
          <mat-icon matListItemIcon>science</mat-icon>
          <span class="mat-list-text">Products</span>
        </a>
        <a mat-list-item [routerLink]="['/user/list']" [routerLinkActive]="['menu-active']">
          <mat-icon matListItemIcon>group</mat-icon>
          <span class="mat-list-text">Users</span>
        </a>
      </mat-nav-list>
    </li>
    <li *ngIf="hasRoleNetSuite">
      <mat-list-item (click)="showNetsuite = !showNetsuite">
        <mat-icon matListItemIcon>webhook</mat-icon>
        <span class="flex">
          <span class="mat-list-text">Netsuite</span>
          <span class="flex-auto"></span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showNetsuite}">expand_more</mat-icon>
        </span>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showNetsuite}" *ngIf="showNetsuite">
        <mat-nav-list>
          <!--<a mat-list-item [routerLink]="['/netsuite','service']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>account_tree</mat-icon>
            <span class="mat-list-text">BU/Depart (Services)</span>
          </a>-->
          <a mat-list-item [routerLink]="['/netsuite','region']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>pin_drop</mat-icon>
            <span class="mat-list-text">Regions</span>
          </a>
          <!--<a mat-list-item [routerLink]="['/netsuite','role']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>badge</mat-icon>
            <span class="mat-list-text">Roles</span>
          </a>
          <a mat-list-item [routerLink]="['/netsuite','user','list']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>manage_accounts</mat-icon>
            <span class="mat-list-text">Users</span>
          </a>-->
        </mat-nav-list>
      </div>
    </li>
    <li *ngIf="hasRoleAdministrator">
      <mat-list-item (click)="showAdministrator = !showAdministrator">
        <mat-icon matListItemIcon>admin_panel_settings</mat-icon>
        <span class="flex">
          <span class="mat-list-text">Administrator</span>
          <span class="flex-auto"></span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showAdministrator}">expand_more</mat-icon>
        </span>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showAdministrator}" *ngIf="showAdministrator">
        <mat-nav-list>
          <a mat-list-item [routerLink]="['/command']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>engineering</mat-icon>
            <span class="mat-list-text">Command</span>
          </a>
          <a mat-list-item [routerLink]="['/monitoring']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>monitoring</mat-icon>
            <span class="mat-list-text">Monitoring</span>
          </a>
          <a mat-list-item [routerLink]="['/station']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>location_on</mat-icon>
            <span class="mat-list-text">Stations</span>
          </a>
          <a mat-list-item [routerLink]="['/user/add']" [routerLinkActive]="['menu-active']">
            <mat-icon matListItemIcon>person_add</mat-icon>
            <span class="mat-list-text">User add</span>
          </a>
        </mat-nav-list>
      </div>
    </li>
  </ul>
</nav>
