import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {REGION_ALL_CHILD_OBJECT, RegionService} from '@staphyt/service';
import {Region} from '@staphyt/common';
import {DeleteDialogComponent} from '@staphyt/ui';
import {RegionFormComponent} from './form/region-form.component';
import {IRemoveRegion, REGION_LIST_ALL_DC} from './list/region-list.component';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent {
  public isLoading = true;
  public displayedColumns: string[] = REGION_LIST_ALL_DC;
  public regions!: Region[];

  constructor(private regionService: RegionService,
              private dialog: MatDialog) {
    this.regionService.setChildObjectConfig(REGION_ALL_CHILD_OBJECT);
    this.getList();
  }

  public add() {
    const dialogRef = RegionFormComponent.open(this.dialog, {
      region: new Region()
    });
    dialogRef.afterClosed().subscribe((addedRegion: Region) => {
      if (addedRegion) {
        this.isLoading = true;
        this.regionService.add(addedRegion).then(() => {
          this.getList(true);
        });
      }
    });
  }

  public click(region: Region) {
    const dialogRef = RegionFormComponent.open(this.dialog, region);
    dialogRef.afterClosed().subscribe((editedRegion: Region) => {
      if (editedRegion) {
        this.isLoading = true;
        this.regionService.edit(editedRegion).then(() => {
          this.isLoading = false;
        });
      }
    });
  }

  public remove(iRemoveRegion: IRemoveRegion) {
    new DeleteDialogComponent(this.dialog).open(iRemoveRegion.event.x, iRemoveRegion.event.y).subscribe((result: boolean) => {
      if (result) {
        this.isLoading = true;
        this.regionService.remove(iRemoveRegion.region).then((success: boolean) => {
          this.getList(true);
        });
      }
    });
  }

  private getList(forceRefresh: boolean = false) {
    this.regionService.fetch(forceRefresh).then(() => {
      this.regionService.getCollection().then((regions) => {
        if (Array.isArray(regions)) {
          this.regions = regions;
          this.isLoading = false;
        }
      });
    });
  }
}
