<h2 mat-dialog-title>
  {{ service.code }} - {{ service.name }}
</h2>
<form [formGroup]="serviceForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div mat-dialog-content>
    <p>
      <mat-form-field>
        <mat-label>NetSuite allowed</mat-label>
        <mat-select formControlName="netSuiteAllowed">
          <mat-option>-- None --</mat-option>
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label>NetSuite type</mat-label>
        <mat-select formControlName="netSuiteType">
          <mat-option *ngFor="let serviceNetSuiteType of _serviceNetsuiteType | keyvalue"
                      [value]="serviceNetSuiteType.key">{{ serviceNetSuiteType.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="serviceForm.get('netSuiteType')?.hasError('required')">
          NetSuiteType is required
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label>regulatory</mat-label>
        <mat-select formControlName="regulatory">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </p>
    <p>
        <span style="display:block; color:rgba(0,0,0,.54); font-size: 10.4px;">
          HR Supervisor
        </span>
      <staphyt-user-list *ngIf="hrSupervisor"
                         [displayedColumns]="hrSupervisorDisplayedColumns"
                         [data]="hrSupervisor"
                         [isLoading]="false"
                         (removeUser)="clearHrSupervisor()">
      </staphyt-user-list>
      <button mat-raised-button color="primary" *ngIf="!hrSupervisor" type="button" (click)="setHrSupervisor()" [disabled]="true !== serviceForm.get('netSuiteAllowed')?.value">Set HR supervisor</button>
    </p>
    <p>
      <mat-form-field>
        <mat-label>approvalLimit</mat-label>
        <input matInput type="number" formControlName="approvalLimit" placeholder="Placeholder">
        <mat-error *ngIf="serviceForm.get('approvalLimit')?.hasError('pattern')">
          Please enter a valid approvalLimit
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label>stationApproval</mat-label>
        <mat-select formControlName="stationApproval">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Annuler</button>
    <button mat-raised-button color="primary" [disabled]="!serviceForm.valid" type="submit">Sauvegarder</button>
  </div>
</form>
