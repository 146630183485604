import { Component, OnInit } from '@angular/core';

export const NETSUITE_ROLES: ReadonlyMap<number, string> = new Map([
  [1, 'Accountant'],
  [1028, 'Staphyt Accounting Assistant'],
  [1049, 'Staphyt Administrative and Accounting'],
  [1048, 'Staphyt Administrative and Accounting (+)'],
  [1047, 'Staphyt Administrative and Accounting (Group)'],
  [1043, 'Staphyt Administrative Assistant'],
  [1042, 'Staphyt Administrator'],
  [1038, 'Staphyt Approver'],
  [1026, 'Staphyt A/P Europe'],
  [1030, 'Staphyt A/P Group'],
  [1044, 'Staphyt A/P Italia'],
  [1031, 'Staphyt A/R'],
  [1033, 'Staphyt API'],
  [1016, 'Staphyt AUS/NZ Accountant'],
  [1012, 'Staphyt Buyer'],
  [1013, 'Staphyt Company management'],
  [1017, 'Staphyt CFO'],
  [1021, 'Staphyt Employee'],
  [1029, 'Staphyt Head Accountant'],
  [1020, 'Staphyt Human Resources'],
  [1027, 'Staphyt Local Accountant'],
  [1015, 'Staphyt Marketing Manager'],
  [1019, 'Staphyt Project Manager'],
  [1046, 'Staphyt AFH Maroc'],
  [1014, 'Staphyt Sales Manager'],
  [1018, 'Staphyt System Administrator'],
  [1041, 'Staphyt Treasury'],
  [1051, 'Staphyt Administrative and Accounting (AFH)'],
  [1052, 'Staphyt Regulatory Affairs - Project Manager'],
  [1053, 'Staphyt Regulatory Affairs - Technical Advisor'],
  [1054, 'Staphyt Regulatory Affairs - SA & Team Leader'],
  [1055, 'Staphyt Regulatory Affairs - Business Dir/Dep'],
  [1059, 'Staphyt Agrosciences - TM'],
  [1060, 'Staphyt Agrosciences - RL / Technician Labo'],
  [1061, 'Staphyt Agrosciences - Technician / QA'],
  [1062, 'Staphyt Agrosciences - BUD TS'],
  [1063, 'Staphyt Agrosciences - BUD Field'],
  [1064, 'Staphyt Agrosciences - SA / TA'],
  [1057, 'Staphyt Expense Report'],
  [1065, 'Staphyt Bank Reconciliation - Fast Four'],
  [1066, 'Staphyt Human Ressources (v2)']
]);

@Component({
  selector: 'app-netsuite',
  templateUrl: './netsuite.component.html',
  styleUrls: ['./netsuite.component.scss']
})
export class NetsuiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
