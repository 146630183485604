import {Component} from '@angular/core';
import {ApiStaphytResponse, ApiStaphytService} from '@staphyt/api';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss']
})
export class CommandComponent {
  public commandInProgress = false;
  public commandResult?: string;
  public selectedCommand?: string;
  public commandStartAt?: Date;
  public commandEndAt?: Date;

  constructor(private apiStaphytService: ApiStaphytService) { }

  public launchCommand(command: string): void {
    this.commandInProgress = true;
    this.selectedCommand = command;
    this.commandEndAt = this.commandResult = undefined;

    const params: Map<string, any> = new Map();
    params.set('name', command);
    this.commandStartAt = new Date();
    this.apiStaphytService.launchRequest(this.apiStaphytService.get('/command', params)).then((apiStaphytResponse: ApiStaphytResponse) => {
      this.commandEndAt = new Date();
      this.commandResult = apiStaphytResponse.member;
      this.commandInProgress = false;
    });
  }

}
