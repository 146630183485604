<div class="alert alert-info" role="alert">
  L'API va automatiquement mettre à jour la liste des services depuis Premium-RH. Dès lors qu'un nouveau service est crée (une notification est envoyée) et il faut définir la configuration pour que ce service puisse être synchroniser sur NetSuite.
  <br />
  A ce jour, la mise à jour vers NetSuite se fait automatiquement le lendemain matin.
</div>
<div class="alert alert-info" role="alert">
  Regulatory a vrai, va assigner le role suivant: Staphyt Regulatory Affairs - SA & Team Leader (#1054);
  <br />
  Sinon va assigner le role:
  <ul>
    <li>Si SALES ADVISOR => Staphyt Agrosciences - SA / TA (#1064)</li>
    <li>Si TEAM LEADER => Staphyt Agrosciences - TM (#1059)</li>
  </ul>
</div>
<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Liste des services provenant de Premium-RH et la visibilité vers NetSuite: BU ou Department</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-service-list [isLoading]="isLoading" [data]="services" [displayedColumns]="displayedColumns" (clickService)="click($event)"></app-service-list>
  </mat-card-content>
</mat-card>
