<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Monitoring list</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex">
      <mat-form-field class="w-80">
        <mat-label>Filter</mat-label>
        <input matInput type="text" #filterInput (keyup)="applyFilter($event)" autocomplete="off" placeholder="Filter">
        <button *ngIf="filterInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <span class="flex-auto"></span>
      <span>
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="dateRangePicker">
            <input matStartDate formControlName="start" placeholder="Start date" #startDate>
            <input matEndDate formControlName="end" placeholder="End date" #endDate>
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #dateRangePicker (closed)="onDateRangePickerClosed()"></mat-date-range-picker>
        </mat-form-field>
      </span>
      <span style="margin-left: 10px;">
        <mat-form-field>
          <mat-label>Filter by client</mat-label>
          <mat-select #filterOauth2Client>
            <mat-option>-- None --</mat-option>
            <mat-option *ngFor="let oauth2Client of oauth2Clients"
                        [value]="oauth2Client">{{ oauth2Client.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </span>
      <span style="margin-left: 10px;">
        <mat-form-field>
          <mat-label>Filter by status</mat-label>
          <mat-select #filterStatus>
            <mat-option>-- None --</mat-option>
            <mat-option *ngFor="let status of _monitoringStatus | keyvalue"
                        [value]="status.key">{{ status.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </div>
    <div class="stats">
      <span *ngFor="let status of _monitoringStatus | keyvalue" class="status status-{{ status.key.toLowerCase() }}">
        <span style="font-size: 30px">{{ getCount(status) }}</span>
        <br />
        {{ status.value }}
      </span>
    </div>
    <div class="product-list-container mat-elevation-z2">
      <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>Client</th>
            <td mat-cell *matCellDef="let row">{{row.client.name}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">{{row.action}}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row">{{row.date | date:'medium' }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row" class="status-{{row.status.toLowerCase()}}">
              <span *ngIf="hasMonitoringMissingEndRecord(row.session)" matTooltip="Missing end (Failed, Success, Warning) for the current session">
                <mat-icon fontIcon="report"></mat-icon>
              </span>
              {{ _monitoringStatus | enumGetValueWithKeyPipe:row.status }}
              <span *ngIf="row.user">
                <staphyt-user-popup [user]="row.user"></staphyt-user-popup>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="session">
            <th mat-header-cell *matHeaderCellDef>Session</th>
            <td mat-cell *matCellDef="let row">{{row.session}}</td>
          </ng-container>

          <ng-container matColumnDef="log">
            <th mat-header-cell *matHeaderCellDef>Log</th>
            <td mat-cell *matCellDef="let row" (click)="click(row)" [ngClass]="{'log-clickable' : row.log}">
              <span class="log">
                {{ row.log }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="errorLog">
            <th mat-header-cell *matHeaderCellDef>Error log</th>
            <td mat-cell *matCellDef="let row" (click)="click(row, true)" [ngClass]="{'log-clickable' : row.errorLog}">
              <span class="log">
                {{ row.errorLog }}
              </span>
            </td>
          </ng-container>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              <div *ngIf="filterInput.value;then noDataMatching else noData"></div>
              <ng-template #noData>No monitoring</ng-template>
              <ng-template #noDataMatching>No monitoring matching the filter "{{ filterInput.value }}"</ng-template>
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
