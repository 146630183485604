import {Component, Inject, OnInit} from '@angular/core';
import {Service, SERVICE_NETSUITE_TYPE, User} from '@staphyt/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {
  USER_LIST_DC_FIRST_NAME,
  USER_LIST_DC_INITIALS,
  USER_LIST_DC_LAST_NAME,
  USER_LIST_DC_REMOVE,
  UserSearchDialogComponent
} from '@staphyt/ui';

export interface IServiceFormComponent {
  service: Service;
}

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent implements OnInit {
  public hrSupervisor?: User;
  public service!: Service;
  public serviceForm!: FormGroup;
  public _serviceNetsuiteType = SERVICE_NETSUITE_TYPE;
  public hrSupervisorDisplayedColumns: string[] = [
    USER_LIST_DC_INITIALS,
    USER_LIST_DC_FIRST_NAME,
    USER_LIST_DC_LAST_NAME,
    USER_LIST_DC_REMOVE
  ];

  constructor(public dialogRef: MatDialogRef<ServiceFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IServiceFormComponent,
              public dialog: MatDialog) { }

  public static open(dialog: MatDialog, data: Service|IServiceFormComponent) {
    if (data instanceof Service) {
      data = {
        service: data
      };
    }
    return dialog.open(ServiceFormComponent, {
      data,
      width: '400px'
    });
  }

  ngOnInit() {
    this.service = this.data.service;
    if (this.service.hrSupervisor) {
      this.hrSupervisor = this.service.hrSupervisor;
    }
    this.serviceForm = this.service.createForm();
  }

  public onSubmit() {
    // if there is no modification made
    if (this.serviceForm.dirty) {
      this.service.updateFromForm(this.serviceForm);
      this.service.hrSupervisor = this.hrSupervisor;
      this.dialogRef.close(this.service);
    } else {
      this.dialogRef.close(null);
    }
  }

  public setHrSupervisor() {
    UserSearchDialogComponent.open(this.dialog).subscribe((user: User) => {
      this.hrSupervisor = user;
      this.serviceForm.markAsDirty();
    });
  }

  public clearHrSupervisor() {
    this.hrSupervisor = undefined;
    this.serviceForm.markAsDirty();
  }
}
