import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {StationService} from '@staphyt/service';
import {Station} from '@staphyt/common';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {StationFormComponent} from './form/station-form.component';

const STATION_LIST_DC_CODE = 'code';
const STATION_LIST_DC_NAME = 'name';
const STATION_LIST_DC_COUNTRY = 'country';
const STATION_LIST_DC_CITY = 'city';
const STATION_LIST_DC_REGION = 'region';
const STATION_LIST_DC_ENABLED = 'enabled';
const STATION_LIST_ALL_DC = [
  STATION_LIST_DC_CODE,
  STATION_LIST_DC_NAME,
  STATION_LIST_DC_COUNTRY,
  STATION_LIST_DC_CITY,
  STATION_LIST_DC_REGION,
  STATION_LIST_DC_ENABLED
];

class StationView extends Station {
  countryName?: string;
  regionName?: string;
}

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent implements OnInit {
  @ViewChild('tableContainer', {static: true}) tableContainer!: ElementRef;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild('filterInput', {static: true}) filterInput!: ElementRef;
  public isLoading = true;
  public dataSource = new MatTableDataSource<StationView>();
  public displayedColumns: string[] = STATION_LIST_ALL_DC;

  constructor(private stationService: StationService,
              private dialog: MatDialog) {
    this.getList();
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe(() => {
      this.tableContainer.nativeElement.scrollTop = 0;
    });

    this.filterInput.nativeElement.focus();
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearFilter() {
    this.filterInput.nativeElement.value = '';
    this.dataSource.filter = '';
  }

  public click(station: Station) {
    const dialogRef = StationFormComponent.open(this.dialog, station);
    dialogRef.afterClosed().subscribe((editedStation: Station) => {
      if (editedStation) {
        this.isLoading = true;
        this.stationService.editPartial(editedStation).then(() => {
          this.getList(true);
        });
      }
    });
  }

  private getList(forceRefresh: boolean = false) {
    this.stationService.fetch(forceRefresh).then(() => {
      this.stationService.getCollection().then((stations) => {
        if (Array.isArray(stations)) {
          const stationViews: StationView[] = [];
          stations.map((station: Station) => {
            const stationView: StationView = station;
            stationView.countryName = station.country.name;
            stationView.regionName = station.region ? station.region.name : '-';
            stationViews.push(stationView);
          });
          this.dataSource.data = stationViews;
          this.isLoading = false;
        }
      });
    });
  }
}
