<mat-stepper linear #stepper>
  <mat-step [stepControl]="stepGetNibelisFormGroup" [hasError]="!stepGetNibelisSuccess">
    <form [formGroup]="stepGetNibelisFormGroup" (ngSubmit)="onSubmitStepGetNibelis()">
      <ng-template matStepLabel>Retrieve user from Nibelis</ng-template>
      <mat-form-field>
        <mat-label>Enter initials of user</mat-label>
        <input matInput #initialsInput formControlName="matr" class="uppercase">
      </mat-form-field>
      <div>
        <button mat-raised-button color="primary" matStepperNext type="submit" [disabled]="!stepGetNibelisFormGroup.valid">Search</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="stepPostStaphytAPIFormGroup" [hasError]="stepPostStaphytAPIStarted && !stepPostStaphytAPISuccess">
    <form [formGroup]="stepPostStaphytAPIFormGroup" (ngSubmit)="onSubmitStepPostStaphytAPI()">
      <ng-template matStepLabel>Complete data and create account</ng-template>
      <div>
        Permet de compléter les informations reçu depuis Nibelis, afin de créer le compte utilisateur dans l'API Staphyt.
      </div>
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>Retrieved info of user from Nibelis</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>Nibelis #id: <input matInput formControlName="nibelisId" readonly></li>
            <li>Initials: <input matInput formControlName="initials" readonly></li>
            <li>Last name: <input matInput formControlName="lastName" readonly></li>
            <li>First name: <input matInput formControlName="firstName" readonly></li>
            <li>Company: <input matInput formControlName="company" readonly><span style="background-color: yellow">TODO, dans le cas ou c'est la société pour Stagiaire et indépendant, voir comment faire</span></li>
            <li>Station: <input matInput formControlName="station" readonly></li>
            <li>Birthdate: <input matInput formControlName="birthdate" readonly></li>
            <li>Contract start date: <input matInput formControlName="contractStartDate" readonly></li>
            <li>Contract end date: <input matInput formControlName="contractEndDate" readonly></li>
            <li>Seniority date: <input matInput formControlName="seniorityDate" readonly></li>
            <li>Supervisor Nibelis #id: <input matInput formControlName="respHierIdNibelis" readonly></li>
            <li>Title: <input matInput formControlName="title" readonly style="width: 500px"></li>
            <li>
              Organization position (from Staphyt API): <input matInput formControlName="organizationPosition" readonly style="width: 500px">
              <mat-card class="card">
                <mat-card-content>
                  Based on:
                  <ul>
                    <li>Department: <input matInput formControlName="department" readonly></li>
                    <li>Service: <input matInput formControlName="service" readonly></li>
                    <li>Team: <input matInput formControlName="team" readonly></li>
                    <li>Division: <input matInput formControlName="division" readonly></li>
                  </ul>
                </mat-card-content>
              </mat-card>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>Complete data and create account</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field>
            <mat-label>SQLServer Id (GED)</mat-label>
            <input matInput type="number" formControlName="gedId">
          </mat-form-field>
          <span style="background-color: yellow">TODO, sera retiré si on offre la possibilité de créer le compte GED</span>
          <br />
          <mat-form-field>
            <mat-label>Username / start of email</mat-label>
            <input matInput formControlName="username">
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Mobile phone</mat-label>
            <input matInput formControlName="mobilePhone">
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Compte activé</mat-label>
            <mat-select formControlName="enabled">
              <mat-option [value]="false">Non</mat-option>
              <mat-option [value]="true">Oui</mat-option>
            </mat-select>
            <mat-hint>S'active automatiquement le jour de début</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext type="submit" [disabled]="!stepPostStaphytAPIFormGroup.valid">Create user</button>
      </div>
    </form>
  </mat-step>
  <mat-step [hasError]="stepPostNetsuiteStarted && !stepPostNetsuiteSuccess">
    <ng-template matStepLabel>Create Netsuite account</ng-template>
    <div>
      Permet de créer le compte Netsuite, celui-ci restera inactif et sera activé par le service Netsuite.
    </div>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Retrieved info of user from GED expertises</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        TODO
        mo_prev => ini_user, job
        mo_jobs => liste des jobs
      </mat-card-content>
    </mat-card>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" matStepperNext (click)="onSubmitStepPostNetsuite()">Create user</button>
    </div>
  </mat-step>
  <mat-step [hasError]="stepPatchStaphytAPIUserStarted && !stepPatchStaphytAPIUserSuccess">
    <ng-template matStepLabel>Set netsuiteId to account</ng-template>
    <div>
      Permet de définir l'id netsuite du compte utilisateur de l'API Staphyt
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" matStepperNext (click)="onSubmitStepPatchStaphytAPIUser()">Set netsuiteId to user</button>
    </div>
  </mat-step>
  <mat-step [hasError]="stepPatchNibelisStarted && !stepPatchNibelisSuccess">
    <ng-template matStepLabel>Set info to Nibelis</ng-template>
    <div>
      Permet de compléter les informations du compte Nibelis : l'id Netsuite ainsi que l'email professionnel.
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" matStepperNext (click)="onSubmitStepPatchNibelis()">Set netsuiteId & email to user</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>More options</ng-template>
    <div>
      Permet de faire d'autres actions en rapport avec le compte.
    </div>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>More options</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>
            Voir pour offrir la possibilité de créer le compte GED
          </li>
          <li>
            Voir pour offrir la possibilité de créer le compte sur l'AD
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" matStepperNext type="submit">Ok</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <ul>
      <li>
        Le compte du portail sera crée automatiquement par synchronisation
      </li>
    </ul>
    You are now done.
    <div>
      <button mat-button matStepperPrevious>Back</button>
    </div>
  </mat-step>
</mat-stepper>
