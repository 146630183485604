import {Component} from '@angular/core';
import {StationService} from '@staphyt/service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent {
  constructor(private stationService: StationService) {
    // Necessary to call this method, because no component actually called getCollection (that doing the childObject process)
    // and the user details popup use the country name so necessary to set child object
    this.stationService.forceSetChildObject();
  }
}
