import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiStaphytResponse, IUserFilter} from '@staphyt/api';
import {UserService} from '@staphyt/service';
import {DialogComponent, IListData, USER_LIST_DC_FIRST_NAME, USER_LIST_DC_INITIALS, USER_LIST_DC_LAST_NAME} from '@staphyt/ui';
import {User} from '@staphyt/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {
  public iListData!: IListData;
  public isLoading = true;
  public pageSize = 20;

  public displayedColumns: string[] = [
    USER_LIST_DC_INITIALS,
    USER_LIST_DC_FIRST_NAME,
    USER_LIST_DC_LAST_NAME
  ];
  public columnsToFilter: string[] = [
    USER_LIST_DC_INITIALS,
    USER_LIST_DC_FIRST_NAME,
    USER_LIST_DC_LAST_NAME
  ];
  private filters: IUserFilter = {
    page: 1,
    itemsPerPage: this.pageSize,
    enabled: true
  };

  constructor(private dialog: MatDialog,
              private userService: UserService) {
    this.loadList();
  }

  public onUser(user: User) {
    DialogComponent.open(this.dialog, {
      user,
      allInfo: true
    });
  }

  public onPaginateChange(pageIndex: number) {
    this.isLoading = true;
    this.filters.page = pageIndex + 1;
    this.loadList();
  }

  public onSearchChange(search: string) {
    this.isLoading = true;
    // always reset page to 1
    this.filters.page = 1;
    this.filters.search_person = null !== search ? [search] : undefined;
    this.loadList();
  }

  private loadList() {
    this.userService.getCollection(this.filters).then((apiStaphytResponse: ApiStaphytResponse) => {
      this.iListData = {
        users: apiStaphytResponse.member,
        resultsLength: apiStaphytResponse.totalItems
      };
      this.isLoading = false;
    });
  }
}
