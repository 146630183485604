<h2 mat-dialog-title>
  <div *ngIf="region.id;then edit else add"></div>
  <ng-template #add>Add a region</ng-template>
  <ng-template #edit>{{ region.name }}</ng-template>
</h2>
<form [formGroup]="regionForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div mat-dialog-content>
    <p>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error *ngIf="regionForm.get('name')?.hasError('required')">
          Name is required
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label>NetSuite #Id</mat-label>
        <input matInput type="number" formControlName="netSuiteId" required>
        <mat-hint>See on NetSuite administration</mat-hint>
        <mat-error *ngIf="regionForm.get('netSuiteId')?.hasError('pattern')">
          Please enter a valid NetSuiteId
        </mat-error>
        <mat-error *ngIf="regionForm.get('netSuiteId')?.hasError('required')">
          NetSuiteId is required
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
    </p>
    <p>
      <span style="display:block; color:rgba(0,0,0,.54); font-size: 10.4px;">
        Leader
      </span>
      <staphyt-user-list *ngIf="leader"
                         [displayedColumns]="leaderDisplayedColumns"
                         [data]="leader"
                         [isLoading]="false"
                         (removeUser)="clearLeader()">
      </staphyt-user-list>
      <button mat-raised-button color="primary" *ngIf="!leader" type="button" (click)="setLeader()">Set leader</button>
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!regionForm.valid" type="submit">Save</button>
  </div>
</form>
