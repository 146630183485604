import {RegionComponent} from './region/region.component';
import {ServiceComponent} from './service/service.component';
import {staphytServiceResolver} from '@staphyt/service';

export const NETSUITE_ROUTES = [
  {
    path: 'region',
    component: RegionComponent,
    resolve: {
      services: staphytServiceResolver
    }
  },
  {
    path: 'service',
    component: ServiceComponent
  }];
