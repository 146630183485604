import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RootComponent} from './root/root.component';
import {NetsuiteComponent} from './netsuite/netsuite.component';
import {
  canActivateAuthenticationGuard, canActivateCheckRole
} from '@staphyt/api';
import {StationComponent} from './station/station.component';
import {ROLES} from '@staphyt/common';
import {NETSUITE_ROUTES} from './netsuite/routes';
import {CommandComponent} from './command/command.component';
import {ProductComponent} from './product/product.component';
import {
  countryResolver,
  regionResolver,
  staphytServiceResolver,
  stationResolver
} from '@staphyt/service';
import {MonitoringComponent} from './monitoring/monitoring.component';
import {Oauth2ClientResolver} from './monitoring/services/oauth2-client/oauth2-client.resolver';
import {USER_ROUTES} from './user/routes';
import {UserComponent} from './user/user.component';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    canActivate: [canActivateAuthenticationGuard, canActivateCheckRole],
    data: {
      expectedRole: ROLES.ROLE_ADMIN
    },
    resolve: {
      stations: stationResolver,
      countries: countryResolver,
      regions: regionResolver,
      services: staphytServiceResolver
    },
    children: [
      {
        path: 'product',
        component: ProductComponent,
        canActivate: [canActivateCheckRole],
        data: {
          expectedRole: ROLES.ROLE_ADMIN_PRODUCT
        }
      },
      {
        path: 'station',
        component: StationComponent,
        data: {
          expectedRole: ROLES.ROLE_ADMINISTRATOR
        }
      },
      {
        path: 'user',
        component: UserComponent,
        children: USER_ROUTES
      },
      {
        path: 'monitoring',
        component: MonitoringComponent,
        resolve: {
          oauth2Client: Oauth2ClientResolver
        },
        data: {
          expectedRole: ROLES.ROLE_ADMINISTRATOR
        }
      },
      {
        path: 'netsuite',
        component: NetsuiteComponent,
        canActivate: [canActivateCheckRole],
        data: {
          expectedRole: ROLES.ROLE_ADMIN_NETSUITE
        },
        children: NETSUITE_ROUTES
      },
      {
        path: 'command',
        component: CommandComponent,
        canActivate: [canActivateCheckRole],
        data: {
          expectedRole: ROLES.ROLE_ADMINISTRATOR
        }
      }]
  },
  { path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
