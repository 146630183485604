import {Component} from '@angular/core';
import {Service} from '@staphyt/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SERVICE_CHILD_OBJECT_SUPERVISOR, StaphytServiceService} from '@staphyt/service';
import {Observable} from 'rxjs';
import {
  SERVICE_LIST_DC_CODE,
  SERVICE_LIST_DC_ENABLED,
  SERVICE_LIST_DC_NAME,
  SERVICE_LIST_DC_SUPERVISOR, SERVICE_LIST_DC_TYPE
} from '../list/service-list.component';

@Component({
  selector: 'app-service-search-dialog',
  templateUrl: './service-search-dialog.component.html',
  styleUrls: ['./service-search-dialog.component.scss']
})
export class ServiceSearchDialogComponent {
  public services!: Service[];
  public isLoading = true;
  public displayedColumns: string[] = [
    SERVICE_LIST_DC_CODE,
    SERVICE_LIST_DC_NAME,
    SERVICE_LIST_DC_TYPE,
    SERVICE_LIST_DC_ENABLED,
    SERVICE_LIST_DC_SUPERVISOR
  ];

  constructor(public dialogRef: MatDialogRef<ServiceSearchDialogComponent>,
              private serviceService: StaphytServiceService) {
    this.serviceService.setChildObjectConfig([SERVICE_CHILD_OBJECT_SUPERVISOR]);
    this.getList();
  }

  public static open(dialog: MatDialog): Observable<Service> {
    return dialog.open(ServiceSearchDialogComponent, {
      height: '630px',
      width: '800px'
    }).afterClosed();
  }

  public onService(service: Service) {
    this.dialogRef.close(service);
  }

  private getList() {
    this.serviceService.getCollection().then((services) => {
      if (Array.isArray(services)) {
        this.services = services;
        this.isLoading = false;
      }
    });
  }
}
