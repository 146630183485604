<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Product list to validate</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <staphyt-product-list
      [data]="products"
      [isLoading]="isLoading"
      [resultsLength]="resultsLength"
      [displayedColumns]="productListDisplayColumns"
      (clickProduct)="onProduct($event)"
      (paginateChange)="onPaginateChange($event)"
      (searchChange)="onSearchChange($event)"
      (onFilterStateChange)="onFilterStateChange($event)"
      (onFilterTypeChange)="onFilterTypeChange($event)"
      [filterStateDefaultValue]="filterStateDefaultValue">
    </staphyt-product-list>
  </mat-card-content>
</mat-card>
