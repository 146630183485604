<mat-form-field [hidden]="!showFilterInput">
  <mat-label>Filter</mat-label>
  <input matInput #filterInput (keyup)="applyFilter($event)" autocomplete="off" placeholder="Filter by name">
  <button *ngIf="filterInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<div class="region-list-container mat-elevation-z8">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <div #tableContainer class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="netSuiteId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NetSuite #Id</th>
        <td mat-cell *matCellDef="let row">{{row.netSuiteId}}</td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled</th>
        <td mat-cell *matCellDef="let row">{{row.enabled ? 'Oui' : 'Non'}}</td>
      </ng-container>

      <ng-container matColumnDef="leader">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Leader</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.leader;then leader else no"></div>
          <ng-template #leader><staphyt-user-popup [user]="row.leader"></staphyt-user-popup></ng-template>
          <ng-template #no>-</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-icon class="cursor-pointer material-icons-outlined" aria-hidden="false" (click)="remove($event, row);$event.stopPropagation()" matTooltip="Delete">delete_outline</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="dataSourceEmpty">
        <td mat-cell *matFooterCellDef>
          No data available
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="click(row)"></tr>
      <tr mat-footer-row *matFooterRowDef="['dataSourceEmpty']" [hidden]="dataSource.filteredData.length > 0"></tr>
    </table>
  </div>
  <mat-paginator [hidden]="!showPaginator" [pageSize]="20" showFirstLastButtons></mat-paginator>
</div>
