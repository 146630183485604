<div class="row p-0 root">
  <div class="col-left">
    <div id="logo">
      <h2>
        Staphyt Admin
      </h2>
    </div>
    <app-navigation></app-navigation>
  </div>
  <div class="col-content">
    <app-top></app-top>
    <div #content id="content">
      <div id="contentTop"></div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
