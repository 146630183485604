import {Component, Inject, OnInit} from '@angular/core';
import {Region, Station} from '@staphyt/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {REGION_LIST_DC_NAME, REGION_LIST_DC_REMOVE} from '../../netsuite/region/list/region-list.component';
import {RegionSearchDialogComponent} from '../../netsuite/region/search/region-search-dialog.component';

export interface IStationFormComponent {
  station: Station;
}

@Component({
  selector: 'app-station-form',
  templateUrl: './station-form.component.html',
  styleUrls: ['./station-form.component.scss']
})
export class StationFormComponent implements OnInit {
  public region?: Region;
  public regionDisplayedColumns: string[] = [
    REGION_LIST_DC_NAME,
    REGION_LIST_DC_REMOVE
  ];
  public station!: Station;
  public stationForm!: FormGroup;

  constructor(public dialogRef: MatDialogRef<StationFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IStationFormComponent,
              private dialog: MatDialog) { }

  public static open(dialog: MatDialog, data: Station|IStationFormComponent) {
    if (data instanceof Station) {
      data = {
        station: data
      };
    }
    return dialog.open(StationFormComponent, {
      data,
      width: '300px'
    });
  }

  ngOnInit() {
    this.station = this.data.station;
    this.stationForm = this.station.createForm();
    if (this.station.region) {
      this.region = this.station.region;
    }
  }

  public onSubmit() {
    // if there is no modification made
    if (this.stationForm.dirty) {
      this.station.updateFromForm(this.stationForm);
      this.station.region = this.region;
      this.dialogRef.close(this.station);
    } else {
      this.dialogRef.close(null);
    }
  }

  public setRegion() {
    RegionSearchDialogComponent.open(this.dialog).subscribe((region: Region) => {
      this.region = region;
      this.stationForm.markAsDirty();
    });
  }

  public clearRegion() {
    this.region = undefined;
    this.stationForm.markAsDirty();
  }
}
