import {Component} from '@angular/core';
import {Region} from '@staphyt/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {REGION_CHILD_OBJECT_LEADER, RegionService} from '@staphyt/service';
import {Observable} from 'rxjs';
import {REGION_LIST_DC_ENABLED, REGION_LIST_DC_LEADER, REGION_LIST_DC_NAME} from '../list/region-list.component';

@Component({
  selector: 'app-region-search-dialog',
  templateUrl: './region-search-dialog.component.html',
  styleUrls: ['./region-search-dialog.component.scss']
})
export class RegionSearchDialogComponent {
  public regions!: Region[];
  public isLoading = true;
  public displayedColumns: string[] = [
    REGION_LIST_DC_NAME,
    REGION_LIST_DC_LEADER,
    REGION_LIST_DC_ENABLED
  ];

  constructor(public dialogRef: MatDialogRef<RegionSearchDialogComponent>,
              private regionService: RegionService) {
    this.regionService.setChildObjectConfig([REGION_CHILD_OBJECT_LEADER]);
    this.getList();
  }

  public static open(dialog: MatDialog): Observable<Region> {
    return dialog.open(RegionSearchDialogComponent, {
      height: '630px',
      width: '800px'
    }).afterClosed();
  }

  public onRegion(region: Region) {
    this.dialogRef.close(region);
  }

  private getList() {
    this.regionService.getCollection().then((regions) => {
      if (Array.isArray(regions)) {
        this.regions = regions;
        this.isLoading = false;
      }
    });
  }
}
