import {Expose, Type} from 'class-transformer';
import {ApiStaphytEntity, IApiStaphytEntity, User} from '@staphyt/common';
import {Oauth2Client} from './oauth2-client';

export enum MONITORING_STATUS {
  FAILED = 'Failed',
  MANUAL = 'Manual',
  SCHEDULED = 'Scheduled',
  SUCCESS = 'Success',
  WARNING = 'Warning'
}

export interface IMonitoring extends IApiStaphytEntity {
  action: string;
  session: string;
  log?: string;
  errorLog?: string;
  date?: Date;
  status: string;
  client: Oauth2Client;
  user?: User;
}

// @dynamic
export class Monitoring extends ApiStaphytEntity implements IMonitoring {
  @Expose({ groups: ['get'] })
  action!: string;
  @Expose({ groups: ['get'] })
  session!: string;
  @Expose({ groups: ['get'] })
  log?: string;
  @Expose({ groups: ['get'] })
  @Type(() => Date)
  date?: Date;
  @Expose({ groups: ['get'] })
  status!: string;
  @Expose({ groups: ['get'] })
  client!: Oauth2Client;
  @Expose({ groups: ['get'] })
  errorLog?: string;
  @Expose({ groups: ['get'] })
  user?: User;
}
