export const environment = {
  production: false,
  apiClientId: '93e98so7r0g8xv8c6zbr405r74z3dp1v',
  loginRedirectUri: 'https://admin.develop.staphyt.com',
  loginUrl: 'https://api.sandbox.staphyt.com/v2/login',
  logoutUrl: 'https://api.sandbox.staphyt.com/v2/logout',
  tokenEndpoint: 'https://api.sandbox.staphyt.com/v2/token',
  apiBaseUrl: 'https://api.sandbox.staphyt.com/v2',
  serviceNibelisBaseUrl: 'https://service-nibelis.develop.staphyt.com',
  serviceNetSuiteBaseUrl: 'https://service-netsuite.develop.staphyt.com'
};
