import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiStaphytResponse, IProductFilter} from '@staphyt/api';
import {PRODUCT_ALL_CHILD_OBJECT, ProductService} from '@staphyt/service';
import {
  PRODUCT_LIST_DC_INGREDIENTS,
  PRODUCT_LIST_DC_NAME,
  PRODUCT_LIST_DC_STATE,
  PRODUCT_LIST_DC_TYPE,
  ProductFormComponent
} from '@staphyt/ui';
import {EnumGetKeyWithValuePipe, Product, PRODUCT_STATE} from '@staphyt/common';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {
  public resultsLength!: number;
  public isLoading = true;
  public products!: Product[];
  public productListDisplayColumns: string[] = [
    PRODUCT_LIST_DC_NAME,
    PRODUCT_LIST_DC_TYPE,
    PRODUCT_LIST_DC_INGREDIENTS,
    PRODUCT_LIST_DC_STATE
  ];
  public pageSize = 20;
  // @ts-ignore
  public filterStateDefaultValue: string = new EnumGetKeyWithValuePipe().transform(PRODUCT_STATE, PRODUCT_STATE.WAITING_FOR_VALIDATION);
  private filters: IProductFilter = {
    page: 1,
    itemsPerPage: this.pageSize,
    state: [this.filterStateDefaultValue]
  };
  private previousSearch: string|null = null;

  constructor(private productService: ProductService,
              private dialog: MatDialog) {
    this.productService.setChildObjectConfig(PRODUCT_ALL_CHILD_OBJECT);
    this.loadList();
  }

  public onProduct(product: Product) {
    ProductFormComponent.open(this.dialog, {
      product,
      validateAction: true
    });
  }

  public onPaginateChange(pageIndex: number) {
    this.isLoading = true;
    this.filters.page = pageIndex + 1;
    this.loadList();
  }

  public onSearchChange(search: string | null) {
    if (search !== this.previousSearch) {
      this.previousSearch = search;
      this.isLoading = true;
      // always reset page to 1
      this.filters.page = 1;
      this.filters.search_product = null !== search && '' !== search ? [search] : undefined;
      this.loadList();
    }
  }

  public onFilterStateChange(state: string | undefined) {
    if (state) {
      this.filters.state = [state];
    } else {
      this.filters.state = undefined;
    }
    this.loadList();
  }

  public onFilterTypeChange(type: string | undefined) {
    if (type) {
      this.filters.type = [type];
    } else {
      this.filters.type = undefined;
    }
    this.loadList();
  }

  private loadList() {
    this.isLoading = true;
    // @ts-ignore
    this.productService.getCollection(this.filters).then((apiStaphytResponse: ApiStaphytResponse) => {
      this.products = apiStaphytResponse.member;
      this.isLoading = false;
      // @ts-ignore
      this.resultsLength = apiStaphytResponse.totalItems;
      this.products = apiStaphytResponse.member;
    });
  }
}
