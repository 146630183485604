import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Service, SERVICE_NETSUITE_TYPE} from '@staphyt/common';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

export const SERVICE_LIST_DC_CODE = 'code';
export const SERVICE_LIST_DC_NAME = 'name';
export const SERVICE_LIST_DC_PARENT = 'parent';
export const SERVICE_LIST_DC_ENABLED = 'enabled';
export const SERVICE_LIST_DC_SUPERVISOR = 'supervisor';
export const SERVICE_LIST_DC_NETSUITE_ALLOWED = 'netSuiteAllowed';
export const SERVICE_LIST_DC_NETSUITE_TYPE = 'netSuiteType';
export const SERVICE_LIST_DC_TYPE = 'type';
export const SERVICE_LIST_DC_HR_SUPERVISOR = 'hrSupervisor';
export const SERVICE_LIST_DC_REGULATORY = 'regulatory';
export const SERVICE_LIST_DC_STATION_APPROVAL = 'stationApproval';
export const SERVICE_LIST_DC_APPROVAL_LIMIT = 'approvalLimit';
export const SERVICE_LIST_DC_REMOVE = 'remove';
export const SERVICE_LIST_ALL_DC = [
  SERVICE_LIST_DC_CODE,
  SERVICE_LIST_DC_NAME,
  SERVICE_LIST_DC_PARENT,
  SERVICE_LIST_DC_TYPE,
  SERVICE_LIST_DC_ENABLED,
  SERVICE_LIST_DC_SUPERVISOR,
  SERVICE_LIST_DC_NETSUITE_ALLOWED,
  SERVICE_LIST_DC_NETSUITE_TYPE,
  SERVICE_LIST_DC_REGULATORY,
  SERVICE_LIST_DC_HR_SUPERVISOR,
  SERVICE_LIST_DC_APPROVAL_LIMIT,
  SERVICE_LIST_DC_STATION_APPROVAL,
  SERVICE_LIST_DC_REMOVE
];

export interface IRemoveService {
  event: MouseEvent;
  service: Service;
}

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
  @ViewChild('tableContainer', {static: true}) tableContainer!: ElementRef;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild('filterInput', {static: true}) filterInput!: ElementRef;
  @Input() isLoading = true;
  @Input() showFilterInput = true;
  @Input() showPaginator = true;
  @Input() displayedColumns: string[] = SERVICE_LIST_ALL_DC;
  @Output() clickService = new EventEmitter<Service>();
  @Output() removeService = new EventEmitter<IRemoveService>();

  public dataSource = new MatTableDataSource<Service>();

  // for template
  public _serviceNetsuiteType = SERVICE_NETSUITE_TYPE;

  @Input()
  set data(data: Service[]|Service) {
    if (data) {
      if (data instanceof Service) {
        this.dataSource.data = [data];
        this.showPaginator = false;
        this.showFilterInput = false;
        this.sort.disabled = true;
      } else {
        this.dataSource.data = data;
      }
    }
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.paginator.page.subscribe(() => {
      this.tableContainer.nativeElement.scrollTop = 0;
    });

    this.filterInput.nativeElement.focus();
  }

  public click(service: Service) {
    this.clickService.emit(service);
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public clearFilter() {
    this.filterInput.nativeElement.value = '';
    this.dataSource.filter = '';
  }

  public remove(event: MouseEvent, service: Service) {
    this.removeService.emit({
      event,
      service
    });
  }
}
