import {UserAddComponent} from './add/user-add.component';
import {UserListComponent} from './list/user-list.component';
import {
  companyResolver
} from '@staphyt/service';


export const USER_ROUTES = [
  {
    path: 'add',
    component: UserAddComponent,
    resolve: {
      companies: companyResolver
    }
  },
  {
    path: 'list',
    component: UserListComponent
  }
];
