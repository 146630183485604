import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  SERVICE_CHILD_OBJECT_HR_SUPERVISOR,
  SERVICE_CHILD_OBJECT_SUPERVISOR,
  StaphytServiceService
} from '@staphyt/service';
import {Service} from '@staphyt/common';
import {ServiceFormComponent} from './form/service-form.component';
import {
  SERVICE_LIST_ALL_DC,
  SERVICE_LIST_DC_APPROVAL_LIMIT,
  SERVICE_LIST_DC_CODE,
  SERVICE_LIST_DC_ENABLED,
  SERVICE_LIST_DC_HR_SUPERVISOR,
  SERVICE_LIST_DC_NAME,
  SERVICE_LIST_DC_NETSUITE_ALLOWED,
  SERVICE_LIST_DC_NETSUITE_TYPE,
  SERVICE_LIST_DC_PARENT,
  SERVICE_LIST_DC_REGULATORY, SERVICE_LIST_DC_REMOVE,
  SERVICE_LIST_DC_STATION_APPROVAL,
  SERVICE_LIST_DC_SUPERVISOR,
  SERVICE_LIST_DC_TYPE
} from './list/service-list.component';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {
  public isLoading = true;
  public displayedColumns: string[] = [
    SERVICE_LIST_DC_CODE,
    SERVICE_LIST_DC_NAME,
    SERVICE_LIST_DC_PARENT,
    SERVICE_LIST_DC_TYPE,
    SERVICE_LIST_DC_ENABLED,
    SERVICE_LIST_DC_SUPERVISOR,
    SERVICE_LIST_DC_NETSUITE_ALLOWED,
    SERVICE_LIST_DC_NETSUITE_TYPE,
    SERVICE_LIST_DC_REGULATORY,
    SERVICE_LIST_DC_HR_SUPERVISOR,
    SERVICE_LIST_DC_APPROVAL_LIMIT,
    SERVICE_LIST_DC_STATION_APPROVAL
  ];
  public services!: Service[];

  constructor(private serviceService: StaphytServiceService,
              private dialog: MatDialog) {
    this.serviceService.setChildObjectConfig([SERVICE_CHILD_OBJECT_SUPERVISOR, SERVICE_CHILD_OBJECT_HR_SUPERVISOR]);
    this.getList();
  }

  public click(service: Service) {
    const dialogRef = ServiceFormComponent.open(this.dialog, service);
    dialogRef.afterClosed().subscribe((editedService: Service) => {
      if (editedService) {
        this.isLoading = true;
        this.serviceService.editPartial(editedService).then(() => {
          this.isLoading = false;
        });
      }
    });
  }

  private getList(forceRefresh: boolean = false) {
    this.serviceService.fetch(forceRefresh).then(() => {
      this.serviceService.getCollection().then((services) => {
        if (Array.isArray(services)) {
          this.services = services;
          this.isLoading = false;
        }
      });
    });
  }
}
