<mat-card class="card">
  <mat-card-header>
    <mat-card-title>NetSuite</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <button mat-raised-button color="primary" [disabled]="commandInProgress" (click)="launchCommand('netsuite_sync_group_sponsor_contact')" matTooltip="{{ 'Create or update groups/sponsors/contacts in NetSuite\nAutomatically launched every minute' }}">Sync groups/sponsors/contacts</button>
    <!--<button mat-raised-button color="primary" [disabled]="commandInProgress" (click)="launchCommand('netsuite_sync_user_edited')" matTooltip="{{ 'Update of users on NetSuite, which have been modified in API\nAutomatically launched each day at 04:00AM and 04:10AM UTC' }}">Sync users edited</button>
    <button mat-raised-button color="primary" [disabled]="commandInProgress" (click)="launchCommand('netsuite_sync_user_not_in')" matTooltip="{{ 'Create users who are not in NetSuite\nAutomatically launched each day at 03:55AM UTC' }}">Sync users not in</button>
    <button mat-raised-button color="primary" [disabled]="commandInProgress" (click)="launchCommand('netsuite_sync_user_job')" matTooltip="{{ '###### TBD #########\nAutomatically launched each day at 03:55AM UTC' }}">Sync user jobs</button>-->
  </mat-card-content>
</mat-card>
<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Portal</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <button mat-raised-button color="primary" [disabled]="commandInProgress" (click)="launchCommand('portal_crontasks')" matTooltip="{{ 'Launch portal crontasks\nAutomatically launched each day at 04:20AM UTC' }}">Launch crontasks</button>
  </mat-card-content>
</mat-card>
<mat-card class="card" *ngIf="selectedCommand">
  <mat-card-content>
    Command: {{ selectedCommand }}
    <br />
    Start: {{ commandStartAt | date:'medium' }}
    <mat-spinner *ngIf="commandInProgress"></mat-spinner>
    <div *ngIf="commandEndAt">End: {{ commandEndAt | date:'medium' }}</div>
    <div class="code" *ngIf="undefined !== commandResult">
      <code *ngIf="'' !== commandResult">{{ commandResult }}</code>
      <code *ngIf="'' === commandResult">No output</code>
    </div>
  </mat-card-content>
</mat-card>
